.party .combo {
    height: 25px;
}

.recap-block {
    margin: 1px;
    border-right: 1px solid;
    flex: auto;
}

.recap-list {
    display: flex;
    flex-flow: column wrap;
    height: 100px;
    list-style: none;
    padding: 0;
}

.recap-element{
    max-width: 110px;
}

.recap-label {
    flex: auto
}

.recap-value {
    text-align: right;
    margin-right: 5px;
    flex: auto;
}