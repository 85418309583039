.spell img {
    width: 40px;
    height: 40px;
    vertical-align: middle;
}

@media (min-width:0) {
    .spell ul {
        display: none;
    }
}

@media (min-width:700px) {
    .spell ul {
        display: inline-block;
        margin: 0;
        padding-left: 20px;
    }
}