.pool-heroes {
  height: 100px;
  overflow: scroll;
  overflow-y: hidden;
}

.pool-heroes .hero img {
  height: 85px;
}

.empty-rank {
  background: var(--label-color);
}

.hero-input {
  width: 30px;
}

@media (min-width: 0) {
  .empty-rank::after {
    content: "Click on hero";
  }

  .hero-carac img {
    height: 85px;
  }
}

@media (min-width: 700px) {
  .empty-rank {
    height: 150px;
  }

  .empty-rank::after {
    content: "Drag hero here";
  }

  .hero-in-party {
    margin-right: 10px;
  }

  .hero-carac {
    display: flex;
  }

  .hero-carac img {
    height: 150px;
  }
}
