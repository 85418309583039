.gamate-filters-container {
    display: flex;
    flex: 0 0 290px;
}

.gamate-filters-section {
    margin-right: 5px;
}

.gamate-filters-section>label {
    display: block;
}